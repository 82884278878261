<template>
	<div class="notice_popup poss">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit">프로젝트 참여 가능 여부 설정</div>
		<div class="poss-bottom">
			<div class="selectlist">
				<div class="subtit">
					<p>참여 가능 여부</p>	
				</div>
				<div class="subselect">
					<SelectComp type="radio" list="Y:참여 가능,N:참여 불가능"  v-model="joinPoss" defValue="Y" />
				</div>
			</div>

			<div class="selectlist">
				<div class="subtit">
					<p>참여 가능일</p>	
				</div>
				<div class="subselect">
					<SelectDate v-if="joinPoss=='N'" tagNm="div" type="date" class="select-box" classM="wd08" classD="wd08" to="Y3" title="일" rules="required" disabled/>
					<SelectDate v-else tagNm="div" v-model="joinPossYyyymmdd"  type="date" class="select-box" classM="wd08" classD="wd08" to="Y3" title="일" rules="required" />
					<p>부터</p>
				</div>
			</div>
		</div>
		<div class="poss-noti">
			<span> 위의 정보로 설정하시겠습니까? </span>
		</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="setJoinPossDate()">확인</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		param : Object,
	},
	
	data() {
		return {
			data : this.param.value,
			joinPossYyyymmdd : null,
			joinPoss : null,
		}
	},

	mounted() {
		//console.log('param', this.param);
	},

	methods : {
		setJoinPossDate() {
			//console.log('data', this.data);
			if(this.joinPossYyyymmdd == null && this.joinPoss == 'Y') {
				alert("날짜를 선택해주세요.");
			} else {
				var param = {
				mberSeq : this.$store.state.userInfo.mberSeq,
				joinPoss : this.joinPoss,
				joinPossYyyymmdd : this.joinPoss == 'N' ? null : this.joinPossYyyymmdd,

				}
				this.$.httpPost('/api/mem/prf/setPossState', param)
					.then(() => {
						alert('변경되었습니다.');
						this.$emit('close');
						this.$router.go(0);
					}) 
			}
			
		},
	}
}
</script>

<style scoped>
.error {
	font-size: 80%;
	color:red;
	position : absolute;
    margin-left: 86px;
	width:200%;
}
</style>